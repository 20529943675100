"use client";

import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  FC,
  ReactNode,
} from "react";
import { useRouter } from "next/navigation";
import { signOut } from "next-auth/react";
import { Route } from "@/routers/types";

interface UserType {
  email: string;
  firstName: string;
  lastName: string;
  picture?: string;
  partners?: number;
  token?: string;
}

interface AuthContextType {
  user: UserType | null;
  login: (userData: UserType) => void;
  loginWithGoogle: () => Promise<void>;
  logout: () => Promise<void>; // Changé en Promise pour gérer l'async
  setCheckoutUrl: (url: string) => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserType | null>(null);
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  const login = (userData: UserType) => {
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
    const storedCheckoutUrl = localStorage.getItem('checkoutUrl');
    if (storedCheckoutUrl) {
      localStorage.removeItem('checkoutUrl');
      window.location.href = storedCheckoutUrl;
    }
      
  };

  // Mise à jour de la fonction logout pour gérer la déconnexion NextAuth
  const logout = async () => {
    try {
      // Nettoyage du contexte local
      setUser(null);
      localStorage.removeItem("user");
      // localStorage.removeItem("checkoutUrl");
      sessionStorage.clear();
      
      // Déconnexion de NextAuth
      await signOut({ redirect: false });
      
      // Redirection
      router.push("/login");
    } catch (error) {
      console.error("Logout error:", error);
      // En cas d'erreur, on s'assure quand même de nettoyer l'état local
      setUser(null);
      localStorage.removeItem("user");
      // localStorage.removeItem("checkoutUrl");
      sessionStorage.clear();
      await signOut({ redirect: false });
      router.push("/login");
    }
  };

  const loginWithGoogle = useCallback(async () => {
    console.log("Google login not implemented");
  }, []);

  const setCheckoutUrl = useCallback((url: string) => {
    localStorage.setItem("checkoutUrl", url);
  }, []);

  useEffect(() => {
    const handleSubmit = async (email: string) => {
      try {
        const response = await fetch("https://esi-fly.com/api/signin/index.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        });

        const data = await response.json();
        if (data.success) {
          if (data.partners) {
            login({
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              picture: data.picture,
              token: data.token,
              partners: data.partners,
            });
          } else {
            login({
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              picture: data.picture,
            });
          }
          if (data.newUser) {
            try {
              await fetch("/api/send/welcome", {
                method: "POST",
                body: JSON.stringify({
                  firstName: data.firstName,
                  lastName: data.lastName,
                  email: data.email,
                }),
              });
            } catch (emailError) {
              console.error("Erreur lors de l'envoi de l'email:", emailError);
            }
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const initializeAuth = async () => {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }

      const params = new URLSearchParams(window.location.search);
      const emailFromUrl = params.get("emailapple");

      if (emailFromUrl) {
        await handleSubmit(emailFromUrl);

        params.delete("emailapple");
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.replaceState({}, document.title, newUrl);

        const storedCheckoutUrl = localStorage.getItem("checkoutUrl");
        if (storedCheckoutUrl) {
          router.push(storedCheckoutUrl as Route);
          // localStorage.removeItem("checkoutUrl");
        }
      }
      // localStorage.removeItem("checkoutUrl");

      setLoading(false);
    };

    initializeAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  if (loading) {
    return <div></div>;
  }

  return (
    <AuthContext.Provider
      value={{ user, login, loginWithGoogle, logout, setCheckoutUrl }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;