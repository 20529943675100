"use client";
import React, { useState } from "react";
import LocationInput from "../LocationInput";
import ArrivalInput from "../ArrivalInput";
import GuestsInput from "../GuestsInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "../../type";
import converSelectedDateToString from "@/utils/converSelectedDateToString";
import { useSearchForm } from "../../(HeroSearchForm)/SearchFormContext";

const FlightSearchForm = () => {
  const [fieldNameShow, setFieldNameShow] = useState<
    "locationPickup" | "locationDropoff" | "dates" | "guests" | "general" | ""
  >("general");
  const [locationInputPickUp, setLocationInputPickUp] = useState("");
  const [locationInputDropOff, setLocationInputDropOff] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const { model, setModel, type_flight, setTypeFlight } = useSearchForm();

  const [dropOffLocationType, setDropOffLocationType] = useState<
    "Aller-retour" | "Aller simple" | ""
  >("Aller-retour");
  const [flightClassState, setFlightClassState] = useState("Hélicoptère");

  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  });

  const renderInputField = (
    label: string,
    value: string,
    onClick: () => void,
    active: boolean,
    children: React.ReactNode
  ) => (
    <div
      className={`w-full bg-white dark:bg-neutral-800 ${
        active
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
      }`}
    >
      {!active ? (
        <button
          className="w-full flex justify-between text-sm font-medium p-4"
          onClick={onClick}
        >
          <span className="text-neutral-400">{label}</span>
          <span>{value}</span>
        </button>
      ) : (
        <div className="max-h-[60vh] overflow-y-auto">{children}</div>
      )}
    </div>
  );

  const renderRadio = (name: string, id: string, label: string) => {
    return (
      <div className="flex items-center">
        <input
          defaultChecked={flightClassState === label}
          id={id + name}
          name={name}
          onChange={() => {
            setFlightClassState(label);
            setModel(label);
          }}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  return (
    <div className="space-y-4 h-full overflow-y-auto">
      {renderInputField(
        "Type de vol",
        `${dropOffLocationType}, ${flightClassState}`,
        () => setFieldNameShow("general"),
        fieldNameShow === "general",
        <div className="p-5">
          <span className="block font-semibold text-xl sm:text-2xl">
            Type de vol
          </span>
          <div className="relative mt-5">
            <div className="flex space-x-2">
              <div
                className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                  dropOffLocationType === "Aller-retour"
                    ? "bg-black shadow-black/10 shadow-lg text-white"
                    : "border border-neutral-300 dark:border-neutral-700"
                }`}
                onClick={() => {
                  setDropOffLocationType("Aller-retour");
                  setTypeFlight("Aller-retour");
                }}
              >
                Aller-retour
              </div>
              <div
                className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                  dropOffLocationType === "Aller simple"
                    ? "bg-black text-white shadow-black/10 shadow-lg"
                    : "border border-neutral-300 dark:border-neutral-700"
                }`}
                onClick={() => {
                  setDropOffLocationType("Aller simple");
                  setTypeFlight("Aller simple");
                }}
              >
                Aller simple
              </div>
            </div>

            <div className="mt-6">
              <label className="text-base font-semibold" htmlFor="">
                Mode de transport
              </label>
              <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                {renderRadio("class", "Hélicoptère", "Hélicoptère")}
                {renderRadio("class", "Jet privé", "Jet privé")}
                {renderRadio("class", "Avion privé", "Avion privé")}
              </div>
            </div>
          </div>
        </div>
      )}

      {renderInputField(
        "Quand",
        startDate
          ? converSelectedDateToString([startDate, endDate])
          : "Ajouter des dates",
        () => setFieldNameShow("dates"),
        fieldNameShow === "dates",
        <DatesRangeInput
          selectsRange={dropOffLocationType === "Aller-retour"}
          href="/listing-flights-private"
        />
      )}

      {renderInputField(
        "Lieu de départ",
        locationInputPickUp || "Lieu de départ",
        () => setFieldNameShow("locationPickup"),
        fieldNameShow === "locationPickup",
        <LocationInput
          headingText="Départ"
          desc="Choisissez votre point de départ"
        />
      )}

      {renderInputField(
        "Lieu d'arrivée",
        locationInputDropOff || "Lieu d'arrivée",
        () => setFieldNameShow("locationDropoff"),
        fieldNameShow === "locationDropoff",
        <ArrivalInput
          headingText="Arrivée"
          desc="Choisissez votre point d'arrivée"
        />
      )}

      {renderInputField(
        "Passagers",
        guestInput.guestAdults || guestInput.guestChildren
          ? `${
              (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0)
            } guests${
              guestInput.guestInfants
                ? `, ${guestInput.guestInfants} infants`
                : ""
            }`
          : "Ajouter des passagers",
        () => setFieldNameShow("guests"),
        fieldNameShow === "guests",
        <GuestsInput />
      )}
    </div>
  );
};

export default FlightSearchForm;
