"use client";

import React, { createContext, useState, useContext, ReactNode } from 'react';

interface SearchFormContextType {
  lat: number | null;
  arrival_lat: number | null;
  lng: number | null;
  arrival_lng: number | null;
  totalGuests: number;
  duration: string | "";
  category: string | "";
  location: string | "";
  location_arrival: string | "";
  email: string | "";
  phone: string | "";
  name: string | "";
  eventtype: string | "";
  date: string | "";
  date_retour: string | "";
  model: string | "";
  type_flight: string | "";
  setLat: (lat: number | null) => void;
  setArrivalLat: (arrival_lat: number | null) => void;
  setLng: (lng: number | null) => void;
  setArrivalLng: (arrival_lng: number | null) => void;
  setDuration: (duration: string) => void;
  setModel: (model: string) => void;
  setTotalGuests: (guests: number) => void;
  setEmail: (email: string | "") => void;
  setPhone: (phone: string | "") => void;
  setName: (name: string | "") => void;
  setCategory: (category: string | "") => void;
  setEventType: (eventtype: string | "") => void;
  setLocation: (location: string | "") => void;
  setLocationArrival: (location_arrival: string | "") => void;
  setDate: (date: string | "") => void;
  setDateRetour: (date_retour: string | "") => void;
  setTypeFlight: (type_flight: string | "") => void;
}

const SearchFormContext = createContext<SearchFormContextType | undefined>(undefined);

export const SearchFormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [lat, setLat] = useState<number | null>(null);
  const [arrival_lat, setArrivalLat] = useState<number | null>(null);
  const [lng, setLng] = useState<number | null>(null);
  const [arrival_lng, setArrivalLng] = useState<number | null>(null);
  const [location, setLocation] = useState('');
  const [location_arrival, setLocationArrival] = useState('');
  const [totalGuests, setTotalGuests] = useState(2);
  const [duration, setDuration] = useState('');
  const [category, setCategory] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [eventtype, setEventType] = useState('');
  const [date, setDate] = useState('');
  const [date_retour, setDateRetour] = useState('');
  const [model, setModel] = useState('Hélicoptère');
  const [type_flight, setTypeFlight] = useState('Aller-retour');

  return (
    <SearchFormContext.Provider value={{
      lat, lng, totalGuests, duration, category, location, email, phone, name, eventtype, date, arrival_lat, arrival_lng, location_arrival, model, type_flight, date_retour,
      setLat, setLng, setTotalGuests, setDuration, setCategory, setLocation, setEmail, setPhone, setName, setEventType, setDate, setArrivalLat, setArrivalLng, setLocationArrival, setModel, setTypeFlight, setDateRetour,
    }}>
      {children}
    </SearchFormContext.Provider>
  );
};

export const useSearchForm = () => {
  const context = useContext(SearchFormContext);
  if (context === undefined) {
    throw new Error('useSearchForm must be used within a SearchFormProvider');
  }
  return context;
};